.flow {
  margin-bottom: 10rem;
}

.flow-title {
  margin-bottom: 3rem;
  text-align: center;
}

.flow-title h2 {
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .flow {
    margin-bottom: 5rem;
  }

  .flow-title {
    text-align: left;
    font-size: 3.2rem;
  }

  .flow .dfc .flow-cards {
    flex-direction: column;
  }

  .flow-card {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
}