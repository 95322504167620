.app{
  width: 100%;

  display: flex;
  justify-content: center;

  font-weight: 400;
}

.app-inner{
  width: 100%;
}

.yellow-text{
  color: #F5C14F;
}

.black-text{
  color: #000000;
  font-weight: 700;
}

.yellow-bg{
  background-color: #F5C14F;
}

.italic{
  font-style: italic;
}

.bold{
  font-weight: 700;
}

.upper{
  text-transform: uppercase;
}

.dfsb{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 130rem;
}

.dfc{
  display: flex;
  justify-content: center;
  align-items: center;
}