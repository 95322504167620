@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,700;1,700&family=Montserrat:ital,wght@0,400;0,600;0,700;0,800;1,600&display=swap');

body,
body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #0E0E0E;
  font-family: 'Jost', sans-serif;
  color: white;
  font-weight: 400;
}

h1,
.h1 {
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

h2 {
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media screen and (max-width: 1920px) {
  html {
    font-size: 62.5%;
  }
}

/* @media screen and (max-width: 1440px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 47%;
  }
} */

@media screen and (max-width: 900px) {
  html {
    font-size: 62.5%;
    height: 100%;
  }

  h1,
  .h1 {
    font-size: 3.2rem;
  }

  .btn {
    font-size: 2rem;
    padding: 1.6rem 3rem;
  }

  .btn svg {
    min-width: 4rem;
  }

  .app {
    padding: 0 2.5rem;
  }

  .footer-bg,
  .img-wrapper {
    min-width: 100vw;
    margin-left: -2.5rem;
  }
}