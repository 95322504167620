.map {
  margin-bottom: 10rem;
}

.section:not(:last-child) {
  margin-right: 8rem;
}

.text {
  margin-bottom: 4rem;
}

.section img {
  width: 67.2rem;
  height: 33.7rem;
}

.map .text {
  font-size: 3rem;
}

@media screen and (max-width: 900px) {
  .map .dfsb {
    flex-direction: column;
  }

  .map img {
    height: 28.2rem;
    max-width: 33rem;
    object-fit: contain;
  }

  .map .text {
    width: 100%;
    font-size: 1.6rem;
    margin-bottom: 0;
  }

  .map .section {
    margin-right: 0;
  }

  .map .btn {
    margin-top: 0;
    width: 100%;
  }

  .map {
    margin-bottom: 5rem;
  }

  .section:first-child {
    width: 100%;
  }
}