.flow-card {
  background-color: #282828;
  border-radius: 7px;
  padding: 2rem;
  height: 12.8rem;
}

.flow-card.dfsb {
  justify-content: start;
}

.flow-card:not(:last-child) {
  margin-right: 1rem;
}

.card-text {
  font-size: 1.7rem;
}

.flow-card-img {
  margin-right: 2.4rem;
  width: 6rem;
}