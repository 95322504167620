.footer {
  padding-top: 9rem;
  padding-bottom: 10rem;

  margin-bottom: 5rem;
}

.footer-bg {
  padding-top: 7.5rem;
  padding-bottom: 10rem;
  height: 40.3rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-btn {
  width: 100%;
  height: 6.6rem;
}

.footer-btn svg {
  margin-right: 1rem;
}

.footer-btn:not(:last-child) {
  margin-right: 1rem;
}

.green {
  background-color: #53A537 !important;
  color: white !important;
}

.h1 {
  margin-bottom: 4.2rem;
}

@media screen and (max-width: 900px) {
  .footer {
    margin-bottom: 5rem;
    padding: 3rem 0 4.6rem 0;
  }

  .footer-title {
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 3rem;
    text-align: left;
    width: 100%;
  }

  .footer-btns.dfsb {
    flex-direction: column;
  }

  .footer-btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .footer .main-layout {
    padding: 0 2.5rem;
  }

}