.img-wrapper {
  width: 100%;
  height: 91.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.order-btn {
  width: fit-content;
  padding: 2rem 7rem;
  margin-top: 4rem;
}

.description {
  text-align: right;
  font-size: 3rem;
  line-height: 130%;
}

.main-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  margin-bottom: 10rem;
}

.title {
  white-space: nowrap;
}

.dfsb.main-inner {
  display: flex;
  flex-direction: column;
}

.left-block {
  align-self: flex-start;
  margin-bottom: 30rem;
}

.right-block {
  align-self: flex-end;
}

@media screen and (max-width: 900px) {

  .right-block,
  .left-block {
    align-self: center;
  }

  .left-block {
    margin-bottom: 3.7rem;
  }

  .title-wrapper .btn {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3.7rem;
  }

  .img-wrapper {
    height: auto;
    padding: 4rem 0 5rem 0;
  }

  .description {
    margin-top: .4rem;
    font-size: 1.4rem;
  }

  .main h2 {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  .main {
    margin-bottom: 5rem;
  }

  .main .main-layout {
    padding: 0 2.5rem;
  }

  .title {
    white-space: wrap;
  }
}