.btn {
  width: auto;
  border-radius: .7rem;
  padding: 2rem 3rem;

  text-wrap: nowrap;
  white-space: nowrap;
  overflow: visible;

  text-align: center;
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 130%;

  cursor: pointer;
}