.logo-wrapper {
  margin-right: 5rem;
}

.info-bar {
  width: 100%;
}

.header {
  width: 100%;
  max-width: 1300px;
  margin: 5rem auto;
}

.info {
  padding: 1rem 3rem;
  border-radius: 0.7rem;
  width: 100%;
  background: linear-gradient(90deg, #282828 26.64%, rgba(40, 40, 40, 0) 100%);
}

.info .yellow-text {
  font-size: 2rem;
}

.info p {
  font-size: 1.8rem;
}

@media screen and (max-width: 900px) {
  .header.dfsb.main-layout {
    flex-direction: column;
  }

  .header .btn {
    width: 100%;
    margin-top: 1rem;
  }

  .logo-wrapper {
    margin-right: 1.6rem;
  }

  .info .yellow-text {
    font-size: 1.6rem;
  }

  .info p {
    font-size: 1.4rem;
  }

  .info {
    padding: 1em 1.6rem;
  }
}